<template>
	<section id="intro" class="main-content intro" :class="{ subpage: !route.name.startsWith('index') }">
		<div class="row">
			<slot name="content">
				<div class="columns column6">
					<h2 class="subtitle">{{ subtitle }}</h2>
					<h1>{{ title }}</h1>
				</div>
				<div class="columns column6">
					<div v-parse-links v-html="content" />
					<p v-if="bookingID">
						<book-button class="button button-cta" :roomid="bookingID" aria-label="Reserve now">
							<span>{{ $t('bookThisRoom') }}</span>
						</book-button>
					</p>
				</div>
			</slot>
			<slot />
		</div>
		<slot name="bgImage">
			<div />
		</slot>
	</section>
</template>

<script setup>
const route = useRoute();

defineProps({
	title: { type: String, default: '' },
	subtitle: { type: String, default: '' },
	content: { type: String, default: '' },
	bookingID: { type: String, default: '' },
});
</script>
