<template>
	<header v-if="headingImage" ref="header" :class="{ subpage: !route.name.startsWith('index') }">
		<div class="hero-image">
			<div v-if="route.name.startsWith('index') && defaults[locale].homepage.headingTitle">
				<div class="hero-content">
					<h2 class="hero-title">{{ defaults[locale].homepage.headingTitle }}</h2>
					<h3 class="hero-subtitle">{{ defaults[locale].homepage.headingSubTitle }}</h3>
				</div>
			</div>
			<picture>
				<source
					v-if="headingImage.imagePortrait && headingImage.imagePortraitWebp"
					:srcset="headingImage.imagePortraitWebp"
					media="(max-width: 600px)"
					type="image/webp"
				/>
				<source
					v-if="headingImage.imagePortrait"
					:srcset="headingImage.imagePortrait"
					media="(max-width: 600px)"
				/>
				<source :srcset="headingImage.imageWebp" type="image/webp" />
				<source :srcset="headingImage.image" />
				<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
			</picture>
			<a v-if="route.name.startsWith('index')" class="scroll-down smooth-scroll" @click="scrollDown()" />
		</div>
	</header>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();

await fetchDefaults();

const header = ref(null);
const scrollDown = () => {
	const scrollHeight = header.value.clientHeight;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};

defineProps({
	headingImage: { type: Object, default: () => {} },
});
</script>

<style lang="scss" scoped>
.hero-image {
	width: 100%;
	height: 100vh;
	position: relative;
}

.hero-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.subpage .hero-image {
	height: 50vh;
}

.hero-image::before {
	content: '';
	z-index: 2;
	position: absolute;
	display: block;
	inset: 0;
	background: rgba(0 0 0 / 20%);
}

.news-item .hero-image::before {
	display: none;
}

.hero-content {
	position: absolute;
	top: 45%;
	left: 0;
	right: 0;
	z-index: 3;
	text-align: center;
	padding: 0 20px;
	color: #fff;
	animation: fade-from-bottom 1.4s ease;
	max-width: 990px;
	margin: 0 auto;
}

.hero-content h2,
.hero-content h3 {
	color: #fff;
	font-weight: 400;
	font-size: 80px;
	letter-spacing: 1px;
}

.hero-content h3 {
	font-size: 26px;
	font-weight: 300;
	font-family: $body-font-family;
}

.scroll-down {
	position: absolute;
	cursor: pointer;
	bottom: 40px;
	left: 0;
	right: 0;
	margin: 0 auto;
	background: url('~/assets/images/icon-scroll-down.png') no-repeat center center;
	width: 59px;
	height: 59px;
	background-size: 59px;
	z-index: 2;
}

@media (max-width: 880px) {
	.hero-content h2 {
		font-size: 62px;
	}

	.hero-content h3 {
		font-size: 24px;
	}
}

@media (max-width: 680px) {
	.hero-content h2 {
		font-size: 54px;
	}

	.hero-content h3 {
		font-size: 22px;
	}
}

@media (max-width: 520px) {
	.hero-content h2 {
		font-size: 46px;
	}

	.hero-content h3 {
		font-size: 20px;
	}
}
</style>
